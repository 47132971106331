import { dispatchEvent, findElements, toArray } from "../lib/utils";
import { fetchDataSource } from '../lib/mapkick';

function redirectToCadastralPortal(coords) {
  const x = Math.round(coords[1]);
  const y = Math.round(coords[0]);

  const url = `https://nahlizenidokn.cuzk.cz/MapaIdentifikace.aspx?l=KN&x=-${x}&y=-${y}`;

  window.open(url, "KnVypis");
  event.stopPropagation();
}

export default class extends ApplicationController {
  static targets = ["embeddable", "modal", "markerToggle"];

  connect() {
    if (window.cachedMaps == null) window.cachedMaps = {};
    if (window.coordsFromSuggest == null) window.coordsFromSuggest = {};

    // const mapElement = document.getElementById("mapSearchContainer");
    // const editor = new PolygonMapEditor(mapElement, {});

    this.service = this.createService();

    if (this.mapConfig.inputable) this.initTogglers();

    if (!this.initVisible) return;

    if (this.service) {
      this.service.initialize(() => this.loadScript());
    }
  }

  disconnect() {
    window.cachedMaps = null;
    window.coordsFromSuggest = null;
  }

  embed(event) {
    const modalElement = $("#modal-with-map");
    modalElement.toggleClass("map-modal");
    modalElement.modal("toggle");

    const element = this.element.querySelector(
      `#${event.currentTarget.dataset.mapkickTarget}`
    );

    if (this.scriptLoaded) this.service.create(this.element, element);
  }

  displayMarker(event) {
    const element = this.element.querySelector(
      `#${event.currentTarget.dataset.mapkickTarget}`
    );
    if (this.scriptLoaded) {
      this.service.displayMarker(this.element, element);
      this.toggleMarkers(this.element, event.target.dataset.toggleType);
    }
  }

  removeMarker(event) {
    const element = this.element.querySelector(
      `#${event.target.dataset.mapkickTarget}`
    );
    if (this.scriptLoaded) {
      this.service.removeMarker(this.element, element);
      this.toggleMarkers(this.element, event.target.dataset.toggleType);
    }
  }

  toggleLayer(event) {
    const type = event.currentTarget.dataset.layerType;

    if (this.scriptLoaded) {
      this.service.toggleLayer(this.element, type);
    }

    event.preventDefault();
    event.stopPropagation();
  }

  // Private

  createService() {
    return new MapService(this);
  }

  loadScript() {
    Loader.async = true;
    Loader.load(
      null,
      { suggest: this.mapConfig.autosuggest },
      this.init.bind(this)
    );
  }

  init() {
    if (this.scriptLoaded) {
      this.service.create(this.element, this.embeddableTarget);
    }
  }

  initTogglers() {
    const [latitude, longitude] = this.service.getCoordsFromInput(this.element);

    const hasCoords = longitude !== null && latitude !== null;
    const toggleClass = hasCoords ? "enable" : "disable";

    this.toggleMarkers(this.element, toggleClass);
  }

  toggleMarkers(container, toggleClass) {
    const elements = findElements(container, "[data-role~=map-marker-toggle]");

    toArray(elements).forEach((elem) => {
      elem.classList.toggle(
        "visually-hidden",
        elem.dataset.toggleType === toggleClass
      );
    });
  }

  get initVisible() {
    return this.toBoolean(this.data.get("initVisible"));
  }

  get mapConfig() {
    return JSON.parse(this.data.get("config"));
  }

  get geometryConfig() {
    return JSON.parse(this.data.get("geometries"));
  }

  get points() {
    return JSON.parse(this.data.get("points"));
  }

  get mapId() {
    return this.data.get("id");
  }

  get scriptLoaded() {
    return !!window.Loader;
  }

  toBoolean(value) {
    return value === "true";
  }
}

class MapService {
  constructor(ctx) {
    this.togglableLayers = ['cadaster', 'forest', 'hunting_sections', 'hunting_trails', 'joint_huntings', 'danger_sections'];
    this.container = ctx.element;
    this.config = ctx.mapConfig;
    this.geometries = ctx.geometryConfig;
    this.layers = {};
    this.points = ctx.points;
  }

  initialize(callback) {
    if (this.isLoaded) {
      callback();
    } else {
      const script = document.createElement("script");
      script.setAttribute("async", true);
      script.setAttribute("id", "seznam");
      script.setAttribute("src", "//api.mapy.cz/loader.js");
      script.setAttribute("type", "text/javascript");

      const onScriptLoad = () => callback();

      const onScriptError = () => {
        script.remove();
      };

      script.addEventListener("load", onScriptLoad);
      script.addEventListener("error", onScriptError);

      document.body.appendChild(script);

      return () => {
        script.removeEventListener("load", onScriptLoad);
        script.removeEventListener("error", onScriptError);
      };
    }
  }

  create = (container, elem) => {
    const uuid = `${container.id}-${elem.id}`;
    if (!window.cachedMaps) return;
    let m = window.cachedMaps[uuid];
    let coordsFromSuggest = window.coordsFromSuggest;

    const [latitude, longitude] = this.getCoordsFromInput(container);
    const [rawCoords, useFallbackCoords] = this.getCoords(container);
    const coords = SMap.Coords.fromWGS84(rawCoords.lon, rawCoords.lat);

    const {
      autosuggest,
      inputable,
      layers_toggleable: layersToggleable,
      marker_layer: { enable: enableMarker, draggable, ...markerOptions },
    } = this.config;

    let hasCoords = false;
    if (!inputable || (longitude && latitude)) {
      hasCoords = true;
    }

    if (m == null) {
      m = new SMap(elem, coords, useFallbackCoords ? 9 : 16);
      window.cachedMaps[uuid] = m;

      this.layers[uuid] = {};
      this.layers[uuid]["winter"] = m.addDefaultLayer(SMap.DEF_TURIST_WINTER);
      this.layers[uuid]["orto"] = m.addDefaultLayer(SMap.DEF_OPHOTO);
      this.layers[uuid]["description"] = m.addDefaultLayer(
        SMap.DEF_HYBRID_SPARSE
      );
      this.layers[uuid]["basic"] = m.addDefaultLayer(SMap.DEF_BASE);
      this.layers[uuid]["turistic"] = m.addDefaultLayer(SMap.DEF_TURIST);
      this.layers[uuid]["ground"] = m.addLayer(
        new SMap.Layer.Tile(
          "MyslHon",
          `${this.config.tiles_url}/guilds`,
          { layers: "Honitby", format: "image/png", transparent: "true", query: '/{zoom}/{x}/{y}.png'}
        )
      );

      this.layers[uuid]["cadaster"] = m.addLayer(
        new SMap.Layer.Tile(
          "wms_kn",
          "https://services.cuzk.cz/wms/wms.asp?version=1.3.0&CRS=EPSG:32633&REQUEST=GetMap&FORMAT=image/png&styles=&TRANSPARENT=True&layers=KN,DEF_BUDOVY,DEF_PARCELY",
          {
            query:
              "&WIDTH={tileSize}&HEIGHT={tileSize}&BBOX={minx},{miny},{maxx},{maxy}",
          }
        )
      );

      this.layers[uuid]["forest"] = m.addLayer(new SMap.Layer.WMTS('forest', "https://apollo.uhul.cz/wmts_islh_lho/WMTService.aspx", {
        Layer: 'PORS',
        tileMatrixSet: 'GoogleMapsCompatibleExt:epsg:3857',
        style: 'default'
      }));

      this.layers[uuid]["cadaster"]._buildQuery = function (tile) {
        const map = this.getMap();
        const LeftTop = tile.toPixel(map);
        const RightBottom = LeftTop.clone().plus(
          new SMap.Pixel(tile.tileSize, tile.tileSize)
        );
        const LeftTopCoords = LeftTop.toCoords(map).toUTM33();
        const RightBottomCoords = RightBottom.toCoords(map).toUTM33();
        return map.formatString(this._options.query, {
          tileSize: tile.tileSize,
          minx: LeftTopCoords[0],
          miny: RightBottomCoords[1],
          maxx: RightBottomCoords[0],
          maxy: LeftTopCoords[1],
        });
      };

      this.layers[uuid]["orto"].enable();
      this.layers[uuid]["description"].enable();
      this.layers[uuid]["ground"].enable();

      m.addDefaultControls();

      const signals = m.getSignals();

      // Display points (markers)
      if (this.points) {
        const markerLayer = new SMap.Layer.Marker('points');

        (this.points || []).forEach(({ table: topography_point }) => {
          // TODO: decorated_topography_points object is serialized weirdly from ruby to JS
          var x = topography_point.coordinates.table.lon;
          var y = topography_point.coordinates.table.lat;
          var coords = SMap.Coords.fromWGS84(x, y);
          const markerUrl = JAK.mel("div", {}, {display: "block", alignItems: "center", justifyContent: "center"});

          if (topography_point.has_custom_label) {
              const opts = {
                  alignSelf: "center",
                  borderRadius: "2px",
                  color: "white",
                  display: "flex",
                  fontSize: "16px",
                  justifyContent: "center",
                  padding: "0 2px",
                  width: "100%",
                  whiteSpace: "nowrap",
                  textShadow: "3px 3px 3px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000",
              }

              const label = JAK.mel("div", {}, opts);
              label.innerHTML = topography_point.custom_label;
              markerUrl.appendChild(label);
          }

          if (topography_point.marker_icon) {
            const iconSvg = JAK.mel("img", {src: topography_point.marker_icon});
            markerUrl.appendChild(iconSvg);
          }

          var marker = new SMap.Marker(coords, null, {url: markerUrl, title: topography_point.title});

          if (topography_point.popup) {
            var card = new SMap.Card();
            card.getBody().innerHTML = topography_point.popup;
            marker.decorate(SMap.Marker.Feature.Card, card);
          }

          markerLayer.addMarker(marker);
        });

        m.addLayer(markerLayer);

        if (m.getZoom() >= 14) {
          markerLayer.enable();
        }

        signals.addListener(window, 'zoom-stop', () => {
          if (m.getZoom() >= 14) {
            markerLayer.enable();
          } else {
            markerLayer.disable();
          }
        });
      }

      let suggest = null;
      if (autosuggest) {
        const suggestInput = container.querySelector(
          `#${elem.id}-search-input`
        );
        suggest = new SMap.Suggest(suggestInput);
        suggest.urlParams({
          bounds: "48.5370786,12.0921668|51.0746358,18.8927040", // Omezení pro ČR
          enableCategories: 1,
          lang: "cs,en",
        });
      }

      const controls = m.getControls();
      for (let i = 0; i < controls.length; i++) {
        if (
          controls[i] instanceof SMap.Control.Compass ||
          controls[i] instanceof SMap.Control.Zoom
        ) {
          m.removeControl(controls[i]);
          i--;
        }
      }

      if (suggest !== null) {
        suggest
          .addListener("suggest", (func) => {
            const data = func.data;
            const suggested = SMap.Coords.fromWGS84(
              data.longitude,
              data.latitude
            );

            window.coordsFromSuggest = Object.assign(coordsFromSuggest, {
              lon: data.longitude,
              lat: data.latitude,
            });
            m.setCenterZoom(suggested, 16);
          })
          .addListener("close", () => { });
      }

      if (!inputable && layersToggleable) {
        signals.addListener(window, "map-click", (evt, _) => {
          const coords = SMap.Coords.fromEvent(evt.data.event, m);
          if (m.getZoom() >= 17 && this.layers[uuid]["cadaster"].isActive()) {
            redirectToCadastralPortal(coords.toJTSK());
            return;
          }

          if (false && m.getZoom() >= 14 && this.layers[uuid]['forest'].isActive()) {
            var [x, y] = coords.toMercator();

            $.ajax({
              url: this.config.admin_forest_details_path,
              method: 'GET',
              data: {
                x: x,
                y: y
              },
              dataType: 'script',
              complete: function (xhr) {
                typeof (ctxMenu) !== 'undefined' && ctxMenu.close();

              }
            });
            return;
          }

          if (coords && this.config.huntings_clickable) {
            const url =
              this.config.huntings_marker_url +
              (this.config.huntings_marker_url?.includes('?') ? '&' : '?') +
              'q[search_for_point][lat]=' + coords.y +
              '&q[search_for_point][lng]=' + coords.x;

            const markerProxy = {
              render: () => {}
            };
            fetchDataSource(markerProxy, url).then(() => {
              if (markerProxy.rawData?.length) {
                markerProxy.rawData.sort((a, b) => {
                  return a?.points_count - b?.points_count;
                })
                const markerData = markerProxy.rawData[0];
                const card = new SMap.Card();
                card.getBody().innerHTML = markerData.popup;
                m.addCard(card, coords);
              }
            });
          }
        });
      }

      signals.addListener(window, 'zoom-stop', () => {
        if (isMobileApp) return;

        if (m.getZoom() < 14) {
          if (this.layers[uuid]['forest'].isActive()) this.layers[uuid]['forest'].wasActive = true;
          this.layers[uuid]['forest'].disable();
        } else {
          if (this.layers[uuid]['forest'].wasActive) {
            this.layers[uuid]['forest'].enable();
            this.layers[uuid]['forest'].wasActive = false;
          }
        }
        this.markToggleAsActive(uuid);
      })

      window.cachedMaps[uuid] = m;
      if (enableMarker && hasCoords) this.displayMarker(container, elem);
    } else {
      window.cachedMaps[uuid] = m;

      if (hasCoords) {
        this.displayMarker(container, elem);
      } else {
        this.removeMarker(container, elem);
      }
    }

    this.setTogglePosition(elem);
    this.markToggleAsActive(uuid);

    window.cachedMaps[uuid] = m;

    if (Object.keys(this.geometries).length > 0) {
      const {
        marker_layer: { icon },
      } = this.config;

      this.geometries.forEach((geometry) => {
        const { type, id, data, options } = geometry;
        new Mapkick[type](id, data, m, Object.assign(options, { icon }));
      });
    }

    m.syncPort();
  };

  toggleLayer = (container, type) => {
    if (!window.cachedMaps) return;
    Object.keys(window.cachedMaps).forEach((uuid) => {

      if (type === 'forest' && window.cachedMaps[uuid].getZoom() < 14) {
        alert('Pro aktuální zoom není mapa dostupná, prosím přibližte mapu')
        return;
      }

      if (this.togglableLayers.includes(type)) {
        this.layers[uuid][type] && this.layers[uuid][type].isActive() ? this.layers[uuid][type].disable() : this.layers[uuid][type].enable();
      } else {
        Object.keys(this.layers[uuid]).forEach(layerName => layerName !== 'cadaster' && this.layers[uuid][layerName].disable());
        this.layers[uuid][type] && this.layers[uuid][type].enable();

        if (type === 'orto') {
          this.layers[uuid]['description'] && this.layers[uuid]['description'].enable();
        } else {
          this.layers[uuid]['description'] && this.layers[uuid]['description'].disable();
        }
      }

      this.layers[uuid]["ground"].enable();
      this.markToggleAsActive(uuid);
    });
  };

  markToggleAsActive = (uuid) => {
    Object.keys(this.layers[uuid]).forEach((layer) => {
      var toggled = document.getElementById("map_" + layer);
      if (toggled) {
        document
          .getElementById("map_" + layer)
          .classList.toggle(
            "active",
            this.layers[uuid][layer] && this.layers[uuid][layer].isActive()
          );
      }
    });
  };

  setTogglePosition = (elem) => {
    const isModal = elem.dataset.mapTarget === "modal";

    document
      .querySelector(".layer-switch-container")
      .classList.toggle("fixed", isModal);
  };

  displayMarker = (container, elem) => {
    const uuid = `${container.id}-${elem.id}`;
    if (!window.cachedMaps) return;
    const map = window.cachedMaps[uuid];
    if (map == null) return;

    const {
      inputable,
      marker_layer: { id },
    } = this.config;
    const layer = map.getLayer(id);
    const [rawCoords, useFallbackCoords] = this.getCoords(container);
    const coords = SMap.Coords.fromWGS84(rawCoords.lon, rawCoords.lat);

    if (layer == null) {
      this.initMarker(container, elem, coords);
    } else {
      this.resetMarker(id, map, layer, coords);
    }

    if (inputable) this.setInputCoords(container, coords.y, coords.x);
    map.setCenterZoom(coords, useFallbackCoords ? 9 : 16);
  };

  initMarker = (container, elem, coords) => {
    const uuid = `${container.id}-${elem.id}`;
    if (!window.cachedMaps) return;
    const m = window.cachedMaps[uuid];

    const {
      inputable,
      marker_layer: { enable: enableMarker, draggable, ...markerOptions },
    } = this.config;

    const { id, icon } = markerOptions;
    const markerLayer = new SMap.Layer.Marker(id);
    const marker = new SMap.Marker(coords, `${id}_marker`, {
      url: icon,
    });

    if (draggable) marker.decorate(SMap.Marker.Feature.Draggable);

    m.addLayer(markerLayer).enable();
    markerLayer.addMarker(marker);

    const signals = m.getSignals();

    if (inputable) {
      signals.addListener(window, "map-click", (evt) => {
        const gps = SMap.Coords.fromEvent(evt.data.event, m);
        marker.setCoords(gps);

        new SMap.Geocoder.Reverse(gps, this.geocode);
      });
    }

    if (draggable) {
      signals.addListener(window, "marker-drag-stop", this.dragStop);
      signals.addListener(window, "marker-drag-start", this.dragStart);
    }
  };

  removeMarker = (container, elem) => {
    const uuid = `${container.id}-${elem.id}`;
    const { id } = this.config.marker_layer;
    if (!window.cachedMaps) return;
    const map = window.cachedMaps[uuid];
    const layer = map.getLayer(id);

    this.removeAttachedListeners(map);

    if (layer !== null) {
      layer.removeAll();
      map.removeLayer(layer);
    }

    this.resetInputCoords(container);
  };

  resetMarker = (id, map, layer, coords) => {
    const marker = layer._markers[`${id}_marker`].marker;
    marker.setCoords(coords);
  };

  getCoords = (container) => {
    const suggested = window.coordsFromSuggest;
    const [latitude, longitude] = this.getCoordsFromInput(container);

    const useFallbackCoords =
      (longitude || (suggested && suggested["lon"]) || this.config.lon) ==
      null &&
      (latitude || (suggested && suggested["lat"]) || this.config.lat) == null;

    const coords = Object.assign(
      {},
      {
        lon:
          longitude ||
          (suggested && suggested["lon"]) ||
          this.config.lon ||
          this.config.fallback_lon,
        lat:
          latitude ||
          (suggested && suggested["lat"]) ||
          this.config.lat ||
          this.config.fallback_lat,
      }
    );

    return [coords, useFallbackCoords];
  };

  dragStart = (evt) => {
    const container = evt.target.getContainer();
    container[SMap.LAYER_MARKER].style.cursor = "move";
  };

  dragStop = (evt) => {
    const container = evt.target.getContainer();
    const coords = evt.target.getCoords();
    container[SMap.LAYER_MARKER].style.cursor = "";

    return new SMap.Geocoder.Reverse(coords, this.geocode);
  };

  geocode = (geocoder) => {
    const { lat_input_id: latitudeId, lon_input_id: longitudeId } = this.config;
    const results = geocoder.getResults();
    const data = Object.assign({
      latitude: results.coords.y,
      longitude: results.coords.x,
    });

    if (!this.setInputCoords(this.container, data.latitude, data.longitude))
      return data;
  };

  redraw = (container, map) => {
    const [latitude, longitude] = this.getCoordsFromInput(container);
    const coords = SMap.Coords.fromWGS84(
      longitude || this.config.lon,
      latitude || this.config.lat
    );

    map.redraw();
  };

  removeAttachedListeners = (map) => {
    if (map == null) return;

    const signals = map.getSignals();
    const { draggable } = this.config.marker_layer;
    let listeners = ["map-click"];

    if (draggable) {
      listeners.push("marker-drag-start");
      listeners.push("marker-drag-stop");
    }

    listeners.forEach((key) => {
      const listener = signals._myHandleFolder[key];

      if (listener) {
        Object.keys(listener).forEach((identifier) => {
          signals.removeListener(identifier);
        });
      }
    });
  };

  getCoordsFromInput = (container) => {
    const { lat_input_id: latitudeId, lon_input_id: longitudeId } = this.config;
    if (latitudeId && longitudeId) {
      const latInput =
        container.querySelector(`#${latitudeId}`) ||
        document.querySelector(`#${latitudeId}`);
      const lonInput =
        container.querySelector(`#${longitudeId}`) ||
        document.querySelector(`#${longitudeId}`);

      if (!(latInput && latInput.value) && !(lonInput && lonInput.value)) {
        return [null, null];
      }

      return [latInput.value, lonInput.value];
    } else {
      return [null, null];
    }
  };

  setInputCoords = (container, lat, lon) => {
    const {
      lat_input_id: latitudeId,
      lon_input_id: longitudeId,
      address_input_id: addressId,
    } = this.config;
    if (!(latitudeId && longitudeId)) return false;

    const latInput =
      container.querySelector(`#${latitudeId}`) ||
      document.querySelector(`#${latitudeId}`);
    const lonInput =
      container.querySelector(`#${longitudeId}`) ||
      document.querySelector(`#${longitudeId}`);

    const addressInput =
      container.querySelector(`#${addressId}`) ||
      document.querySelector(`#${addressId}`);

    if (!(latInput && lonInput)) return false;

    latInput.setAttribute("value", lat ? lat : "");
    lonInput.setAttribute("value", lon ? lon : "");

    dispatchEvent(latInput, "input");
    dispatchEvent(lonInput, "input");

    if (addressInput) {
      if (lat && lon) {
        const coords = SMap.Coords.fromWGS84(lon, lat);

        new SMap.Geocoder.Reverse(coords, this.fetchAddress);
      } else {
        addressInput.setAttribute("value", "");
      }
    }
  };

  resetInputCoords(container) {
    this.setInputCoords(container, null, null);
  }

  fetchAddress = (geocoder) => {
    const { address_input_id: addressId } = this.config;
    if (!addressId) return false;

    const results = geocoder.getResults();
    const address = results.label || "";
    const addressInput = document.querySelector(`#${addressId}`);

    if (addressInput) {
      addressInput.setAttribute("value", address);
    }
  };

  get isLoaded() {
    return !!window.Loader;
  }
}
